import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LawArticleStatus } from '../law-article-status';
import { LawArticleStatusLabelPipe } from './law-article-status-label.pipe';

@Component({
  selector: 'jrp-law-article-status',
  imports: [LawArticleStatusLabelPipe],
  templateUrl: './law-article-status.component.html',
  styleUrl: './law-article-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '["jrp-law-article-status", status().toLowerCase()]',
  },
})
export class LawArticleStatusComponent {
  readonly status = input.required<LawArticleStatus>();
}
